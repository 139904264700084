import { useEffect } from "react";
import { useRouter } from "next/router";
import { IMPERSONATION } from "../constants/localStorageKeys";

export default function withPublic(
  PublicComponent,
  getExtendProps = (props) => ({
    ...props,
  })
) {
  const withThing = (props) => {
    const router = useRouter();
    const offerRefCode = router.query?.refCode;
    const isMobile = window.innerWidth < 750;

    useEffect(() => {
      if (isMobile && offerRefCode && router.pathname === "/login") {
        router.push(`/redirect-app?refCode=${offerRefCode}`);
      }
    }, [isMobile, offerRefCode]);

    useEffect(() => {
      if (!props.valid) {
        router.push("/");
      }
    }, []);
    if (props.valid) return <PublicComponent {...props} />;
    return null;
  };

  withThing.getInitialProps = async (ctx) => {
    try {
      const { req, res, store, pathname } = ctx;
      let valid = false;
      const isImpersonatePage = pathname?.includes(IMPERSONATION);

      const {
        auth: { user },
      } = store.getState();
      if (!user || !user?.isVerified || isImpersonatePage) valid = true;
      else {
        // server only
        if (req && res) {
          res.writeHead(307, { Location: "/" });
          res.end();
        }
        return { valid: false };
      }
      return getExtendProps({ valid, isPublic: true });
    } catch (err) {
      return {};
    }
  };

  return withThing;
}
