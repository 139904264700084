import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Login from "components/Login";
import ForgotPassword from "components/ForgotPassword";
import ConfirmSentLink from "components/ForgotPassword/ConfirmSentLink";
import { signin } from "reducers/auth";
import { useSelector } from "react-redux";
import { getCountriesGeo } from "@services/info";
import { useGeoLocation } from "@utils/customHook";
import { actionLog } from "@services/common";
import { ACTION_LOG_ENUM } from "@constants";
import { AmptitudeEvents } from "@services/amptitude_events";
import { APP_OPEN, LOGIN_CLICKED } from "@services/amptitude_contants";
import { Helmet } from "react-helmet";
import { projectId } from "../../services/firebaseConfig.json";

const Root = styled.div`
  padding-top: 64px;
  padding-left: 100px;
  padding-bottom: 64px;
  // .wrapper {
  //   box-shadow: 1px 1px 10px #888888;
  //   background: white;
  // }
  .right {
    background: url("/static/images/backgrounds/login-banner.gif") center center
      no-repeat;
    background-size: cover;
    margin: 0 -12px;
  }
`;

const TextDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  flex-direction: row;
  gap: 21px;
`;

const IconTextDiv = styled.div`
  margin-top: 35px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const useStyles = makeStyles({
  mainText: {
    paddingLeft: 10,
    color: "white",
    fontWeight: 600,
    fontSize: 28,
  },
  listItem: {
    fontWeight: 500,
    color: "white",
    whiteSpace: "nowrap",
  },
});

const Index = (props) => {
  const classes = useStyles();
  if (props.type === "confirmSent") return <ConfirmSentLink />;
  const isLoggingIn = useSelector((state) => state.auth.isLoggingIn);

  useEffect(() => {
    document.title = "Login | FruPro";
    const initialize = async () => {
      return await AmptitudeEvents("Login", "", APP_OPEN);
    };
    initialize();
  }, []);

  const _signin = async (email, password) => {
    const response = props.dispatch(
      signin(email, password, handleCreateLoginLogs)
    );
  };
  const location = useGeoLocation();

  const getScreenResolution = () => {
    return `${window.screen.availWidth}x${window.screen.availHeight}`;
  };

  const handleCreateLoginLogs = () => {
    const screenResolution = getScreenResolution();
    const { coordinates } = location || {};

    const newLocation = coordinates ? coordinates : null;

    actionLog(
      {
        screenResolution,
        location: newLocation,
      },
      ACTION_LOG_ENUM.LOGIN
    );
  };

  const textItems = [
    {
      title: "Get Paid in 2Days!",
      subtitle: "Invoice financing for Suppliers",
    },
    {
      title: "Daily buyer demands",
    },
    {
      title: "Quick surplus donation & pickup*",
    },
    {
      title: "Reliable partners with clear terms and trading histories",
    },
    {
      title: "Multilingual agents matching you in new markets",
    },
    {
      title: "Increase your global sales",
    },
    { title: "Subscribe Weekly new buyer opportunities" },
  ];

  return (
    <Root>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {projectId === "frupro-staging"
            ? "🟡Login | FruPro"
            : "Login | FruPro"}
        </title>
      </Helmet>
      <div className="container">
        <div className="wrapper">
          <div className="row">
            <div className="col-5">
              <div>
                {props.type === "login" ? (
                  <Login
                    isLoggingIn={isLoggingIn}
                    onLogin={_signin}
                    disabledLogin={!location.loaded}
                  />
                ) : (
                  <ForgotPassword
                    onSubmit={props.onSubmit}
                    countries={props.countries}
                  />
                )}
              </div>
            </div>
            <Box
              style={{
                paddingLeft: 55,
                paddingTop: 5,
                display: "flex",
                flexDirection: "column",
              }}
              className="col-5"
            >
              <Typography className={classes.mainText}>
                Your Fresh Produce Network
              </Typography>
              <IconTextDiv>
                {textItems.map((item) => {
                  return (
                    <TextDiv>
                      <img src="/static/svg/Done_ring_round_light.svg" />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className={classes.listItem}>{item?.title}</span>
                        {item?.subtitle && (
                          <span className={classes.listItem}>
                            {item?.subtitle}
                          </span>
                        )}
                      </div>
                    </TextDiv>
                  );
                })}
                <span className={classes.listItem}>*Currently UK only</span>
              </IconTextDiv>
            </Box>
          </div>
        </div>
      </div>
    </Root>
  );
};

Index.getInitialProps = async ({}) => {
  try {
    const countries = await getCountriesGeo();
    return { countries };
  } catch (e) {
    return { countries: [] };
  }
};

export default connect()(Index);
