import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Link from "next/link";

const Root = styled.div`
  width: 400px;
  height: fit-content;
  padding: 40px;
  margin-top: 64px;
  box-shadow: 1px 1px 10px #888888;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 14;
  text-align: center;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
  }
  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

const DOCS_VALID_OTP = 5;

const ConfirmSentLink = () => {
  const [timer, setTimer] = useState(DOCS_VALID_OTP);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimer((val) => (val > 0 ? val - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  });

  const handleResend = () => {
    // TODO
    setTimer(DOCS_VALID_OTP);
  };

  const disableResend = timer > 0;

  return (
    <Container>
      <Root>
        <img
          width="81"
          height="110"
          src="/static/images/icons/ic_success.png"
        />
        <span className="title">Success</span>
        <span>
          Your reset link has been sent to your email. Please check all inbox
          mail (include spam, etc.).
        </span>
        <div>
          <Link href={""}>
            <a
              onClick={handleResend}
              style={
                disableResend ? { pointerEvents: "none", color: "grey" } : null
              }
            >
              Resend
            </a>
          </Link>{" "}
          {timer === 60 ? "(01:00)" : timer > 0 ? `(00:${timer})` : null}
        </div>
      </Root>
    </Container>
  );
};

export default ConfirmSentLink;
