import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius:20px;
  background: white !important;
  padding-right: 8px;
  input {
    padding-right: 50px;
  }
  span {
    cursor: pointer;
    color: ${(props) => props.theme.texts.secondary};
    margin-left: 8px;
  }
  .green-icon {
    color: ${(props) => props.theme.colors.success};
  }
`;
const Index = ({ type, valid, ...props }) => {
  const [show, setShow] = useState(false);
  return (
    <Root>
      <Form.Control   type={show ? "text" : "password"} {...props} />
      <span onClick={() => setShow(!show)}>
        {show?   <img src="/static/svg/View_light.svg"/>:   <img src="/static/svg/View_light.svg"/>}
      </span>
      {valid ? (
        <span>
          <i className="fa fa-check-circle green-icon" aria-hidden="true"></i>
        </span>
      ) : null}
    </Root>
  );
};

export default Index;
