import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Link from "next/link";
import { LoginScreenButtonPrimary,LoginScreenButtonSecondary } from "components/Button";
import PasswordField from "components/Form/Password";
import { PASSWORD_REGEX } from "@utils/constants";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { AmptitudeEvents } from "@services/amptitude_events";
import { CREATE_YOUR_ACCOUNT_CLICKED } from "../../services/amptitude_contants";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Must be a valid email address")
    .max(100, "*Email must be less than 100 characters")
    .required("*This is required"),
  password: Yup.string()
    .matches(
      PASSWORD_REGEX,
      "*Password must be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    )
    .required("*This is required"),
});

const Root = styled.div`
  padding: 40px 40px 24px 40px;
  background: rgba(249, 249, 249, 0.9);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15.5px);
  border-radius: 20px;
  .footer {
    color: #858688;
    font-size: 12px;
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Title = styled.h4`
  font-size: 28px;
  font-weight: 600px;
  color: #e77228;
`;

const Index = ({ isLoggingIn, onLogin, disabledLogin }) => {
  const router = useRouter();

  const onNavigate = async () => {
    router.push("/sign-up");
    return await AmptitudeEvents("Login","", CREATE_YOUR_ACCOUNT_CLICKED);
  };

  return (
    <Root>
      <Title>Welcome to FruPro</Title>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          onLogin(values.email, values.password);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label style={{ fontSize: "1rem", color: "#000000" }}>
                Email
              </Form.Label>
              <Form.Control
                style={{ borderRadius: 20, background: "white" }}
                type="text"
                name="email"
                placeholder="example@gmail.com"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label style={{ fontSize: "1rem", color: "#000000" }}>
                Password
              </Form.Label>
              <PasswordField
                style={{ borderRadius: 20, backgroundColor: "white" }}
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
              {touched.password && errors.password ? (
                <div className="text-danger">{errors.password}</div>
              ) : null}
            </Form.Group>
            <LoginScreenButtonPrimary
               
              variant="primary"
              type="submit"
              className="mt-5 w-100 fw-bold"
            >
              {isLoggingIn ? (
                <CircularProgress color="white" size={18} />
              ) : (
                "Login"
              )}
            </LoginScreenButtonPrimary>
            <LoginScreenButtonSecondary
              variant="secondary"
              className="mt-4 w-100 fw-bold"
              disabled={isLoggingIn}
              onClick={onNavigate}
            >
            Create Your Account
            </LoginScreenButtonSecondary>
          </Form>
        )}
      </Formik>
      <div className="mt-4 m-2 footer">
        <Link getInitialProps href="/forgot-password">
          <a style={{ fontSize: "1rem",color:"#69DCAE" }}>Forgot password?</a>
        </Link>
        <div className="mt-2">
          <div style={{ fontSize:16 }}>
            By clicking on Login, you agree to FruPro’s{" "}
            <Link href="https://frupro.com/legal/terms-and-conditions/">
              <a>Terms</a>
            </Link>{" "}
            and{" "}
            <Link href="https://frupro.com/legal/terms-and-conditions/">
              <a>Conditions of use</a>
            </Link>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Index;
