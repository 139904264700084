import React from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Link from "next/link";
import PhoneInput from "components/PhoneInput";
import { isValidPhoneNumber } from "libphonenumber-js";
import { makeStyles } from "@material-ui/core";
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .test("invalidPhone", "*Phone number is invalid", function (phoneNumber) {
      if (this.parent.country && phoneNumber) {
        if (!isValidPhoneNumber(phoneNumber, this.parent.country.countryCode)) {
          return false;
        }
      }
      return true;
    })
    .matches(phoneRegExp, "*Phone number is invalid")
    .required("*This is required"),
});

const Root = styled.div`
  padding: 40px;
  padding-left: 28px;
  padding-right: 28px;
  background: rgba(249, 249, 249, 0.9);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(15.5px);
  border-radius: 20px;
  .phone-group {
    background: ${(props) => props.theme.backgrounds.grey2};
    & > .MuiInput-root {
      & > .MuiInput-input {
        background: transparent;
      }
    }
  }
  label {
    color: #858688;
    font-size: 12px;
  }
  .form-control {
    border: none;
    outline: none;
    box-shadow: none;
    background: #f7f7f7;
    padding: 16px 12px 12px 12px;
    border-bottom: 1px solid #e8e8e8;
  }
`;
const Submit = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  border-color: ${(props) => props.theme.colors.primary} !important;
  border-radius: 0;
  padding: 12px 14px;
  box-shadow: none !important;
`;

const useStyles = makeStyles({
  Phone: {
    backgroundColor: "white",
    borderRadius: 20,
    width: 100,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

const ForgotPassword = ({ submitting, onSubmit, countries }) => {
  const classes = useStyles();
  const initialValues = {
    country: countries.find((c) => c.countryCode === "GB"),
    phoneNumber: "",
  };

  return (
    <Root>
      <h4 style={{ color: "#E77228" }}>Forgot password</h4>
      <div className="text-secondary">
        We’ll send an OTP code to reset your password.
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({ country, ...values }) => {
          const { phone, ..._country } = country;
          onSubmit({ ...values, country: _country });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Phone</Form.Label>
              <div
                style={{ borderRadius: "20px", display: "flex", gap: 10 }}
                className="phone-group"
              >
                <PhoneInput
                  className={classes.Phone}
                  country={values.country}
                  countries={countries}
                  onChange={(event) =>
                    setFieldValue("country", event.target.value)
                  }
                />
                <Form.Control
                  style={{ background: "white", borderRadius: 20 }}
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone number"
                  onChange={(e) =>
                    setFieldValue(
                      "phoneNumber",
                      e.target.value.replace(/\s/g, "")
                    )
                  }
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              </div>
              {touched.phoneNumber && errors.phoneNumber ? (
                <div className="text-danger">{errors.phoneNumber}</div>
              ) : null}
            </Form.Group>
            <Submit
              variant="primary"
              style={{ borderRadius: "20px" }}
              type="submit"
              className="mt-5 w-100"
              disabled={submitting}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Submit>
          </Form>
        )}
      </Formik>
      <div className="text-center mt-2">
        <Link href="/login">
          <a style={{ textDecoration: "none", color: "#E77228" }}>
            Back to Sign in
          </a>
        </Link>
      </div>
    </Root>
  );
};

export default ForgotPassword;
